@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// @import "assets/animate.css";

:root[mode="ios"] .lato,
:root[mode="md"] .mlatouli {
  --ion-font-family: "Lato" !important;
  font-family: "Lato" !important;
}
 
:root[mode="ios"] .muli,
:root[mode="md"] .muli {
  --ion-font-family: "Muli" !important;
  font-family: "Muli" !important;
}
:root[mode="ios"] .srisakdi,
:root[mode="md"] .srisakdi {
  --ion-font-family: "Srisakdi" !important;
  font-family: "Srisakdi" !important;
}
:root[mode="ios"] .qranklestein,
:root[mode="md"] .qranklestein {
  --ion-font-family: "Qranklestein" !important;
  font-family: "Qranklestein" !important;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: normal;
  src: url("./assets/fonts/Lato-Italic.ttf");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/Lato-Black.ttf");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Lato-Medium.ttf");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Lato-Bold.ttf");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/Lato-BoldItalic.ttf");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 500;
  src: url("./assets/fonts/Lato-Medium.ttf");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 900;
  src: url("./assets/fonts/Lato-BlackItalic.ttf");
}

@font-face {
  font-family: "Qranklestein";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Qranklestein.ttf");
}

@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Muli.ttf");
}
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Muli.ttf");
}
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: normal;
  src: url("./assets/fonts/Muli-Italic.ttf");
}
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/Muli-Light-Italic.ttf");
}
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Muli-Light.ttf");
}
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Muli-SemiBold.ttf");
}
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 500;
  src: url("./assets/fonts/Muli-Semi-BoldItalic.ttf");
}
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/Muli-Bold.ttf");
}
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: bold;
  src: url("./assets/fonts/Muli-BoldItalic.ttf");
}
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 100;
  src: url("./assets/fonts/Muli-ExtraLightItalic.ttf");
}
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/Muli-ExtraLight.ttf");
}


.searchbar-input {
  border-radius: 25px !important;
  height: 30px !important;
}
.searchbar-search-icon.sc-ion-searchbar-md {
  left: 90% !important;
  top: 6px;
}
.searchbar-clear-button.sc-ion-searchbar-md {
  left: 13px !important;
  right: 0 !important;
}
.searchbar-search-icon.sc-ion-searchbar-ios {
  color: white !important;
  height: 87% !important;
}


ion-popover {
  --height: 20%;
  --max-height: 20%;
}

ion-item {
  --highlight-color-valid: #ffffff00;
  --inner-border-width: 0px 0px 0px 0px;
  --border-width: 0px 0px 0px 0px;
}

ion-item-sliding {
  --highlight-color-valid: #ffffff00;
  --inner-border-width: 0px 0px 0px 0px;
  --border-width: 0px 0px 0px 0px;
}

.sc-ion-searchbar-ios-h {
  padding-bottom: 0 !important;
}

.ion-color-d3blue {
  --ion-color-base: #0c96df;
  --ion-color-base-rgb: 12, 150, 223;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #0f80cf;
  --ion-color-tint: #1ba0ec;
}
.ion-color-d4blue {
  --ion-color-base: #37a9d8;
  --ion-color-base-rgb: 12, 150, 223;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #37a9d8;
  --ion-color-tint: #37a9d8;
}
.ion-color-d3green {
  --ion-color-base: #00c06d;
  --ion-color-base-rgb: 0, 192, 109;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #00c06d;
  --ion-color-tint: #00c06d;
}
.ion-color-rose {
  --ion-color-base: #ff7d91;
  --ion-color-base-rgb: 255, 125, 145;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #ff7d91;
  --ion-color-tint: #ff7d91;
}
.ion-color-firebase {
  --ion-color-base: #ffa000;
  --ion-color-base-rgb: 255, 160, 0;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #e69100;
  --ion-color-tint: #ffbe4d;
}

.custom-class {
  height: 100px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.mto {
  margin-top: 12px;
  ion-icon {
    margin-bottom: -2px;
  }
}

  h1 {
    font-size: 2.0em;
    font-weight: bold;
    color: var(--ion-color-primary);
    font-family: "Lato" !important;
    font-style: 900;
    padding: .5em;
  }

  h2 {
    font-size: 1.2em;
    font-weight: bold;
    color: var(--ion-color-primary);
    font-family: "Lato" !important;
    font-style: 900;
    padding: .1em;
  }

  h3 {
    font-size: .9em;
    font-weight: bold;
    color: var(--ion-color-secondary);
    font-family: "Lato" !important;
    font-style: normal;
    padding: .1em;
  }

  h4 {
    font-size: .8em;
    font-weight: bold;
    color: var(--ion-color-secondary);
    font-family: "Lato" !important;
    font-style: normal;
    padding: .1em;
  }

  ion-title {
    font-size: 1.5em;
    font-weight: bold;
    color: var(--ion-color-secondary);
    font-family: "Lato" !important;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 4px;
  }
  
  ion-content{
    // --background: var(--ion-color-dark);
  }

// Helpers //
.w100 {
  width: 100%;
}

.bold,
.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.text08 {
  font-size: 0.8rem;
}

.text1 {
  font-size: 1rem !important;
}

.text12 {
  font-size: 1.2rem;
}

.text15 {
  font-size: 1.5rem;
}

.text20 {
  font-size: 2rem;
}

.text25 {
  font-size: 2.5rem;
}

.text-i {
  color: var(--ion-inverse-text-primary);
}

.text-i2 {
  color: var(--ion-inverse-text-secondary);
}

.pt-1 {
  padding-top: 4px; // 16px is the var for padding-horizontal
}

.pt-2 {
  padding-top: 8px;
}

.pt-3 {
  padding-top: 12px;
}

.pt-4 {
  padding-top: 16px;
}

.pb-1 {
  padding-bottom: 4px; // 16px is the var for padding-horizontal
}

.pb-2 {
  padding-bottom: 8px;
}

.pb-3 {
  padding-bottom: 12px;
}

.pb-4 {
  padding-bottom: 16px;
}

.mt-1 {
  margin-top: 4px; // 16px is the var for padding-horizontal
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-4 {
  margin-top: 16px;
}

.mb-1 {
  margin-bottom: 4px; // 16px is the var for padding-horizontal
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 16px;
}

//small caps by hunter.. didnt want to override another class
.hb-small-caps {
  font-size: 10px !important;
  line-height: 13px;
  font-weight: 600;
  text-transform: uppercase;
  vertical-align: baseline;
  letter-spacing: 0.5px;
}

.text-primary {
  color: var(--ion-text-primary) !important;
}

.text-secondary {
  color: var(--ion-text-secondary) !important;
}

.text-disabled {
  color: var(--ion-text-disabled) !important;
}

.text-dark {
  color: var(--ion-color-dark) !important;
}

.text-danger {
  color: var(--ion-color-danger) !important;
}
  ion-card {
    margin: auto;
    padding: auto;
    position: relative;
    text-align: center;
    border: 4px solid var(--ion-color-primary);
    border-radius: 30px !important;
    // --background: var(--ion-color-primary-contrast);
    
    ion-card-header {
      text-align: center;
      font-size: 2.0em;
      width: 100%;
      font-weight: normal;
      color: var(--ion-color-primary);
    }
  }

  ion-segment {
    --background: var(--ion-color-light);

  }

  ion-segment-button {
    // --background: var(--ion-color-primary);
    // --background-checked: var(--ion-color-primary);
    // --background-hover: var(--ion-color-light);
    // color: var(--ion-color-dark);
    // height: 30px;
    margin-left: .5rem;
    margin-right: .5rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    border-width: 3px;
    border-style: solid;
    border-color: var(--ion-color-primary);
    // border-radius: 50px;

    --background: var(--ion-color-primary);
    --background-checked: var(--ion-color-light);
    --background-hover: var(--ion-color-light);
    --border-color: var(--ion-color-primary);
    --border-radius: 50px;
    --border-style: solid;
    --border-width: 0px;
    --color: var(--ion-color-light);
    --color-checked: var(--ion-color-primary);
    --color-focused: var(--ion-color-primary);
    --color-hover: var(--ion-color-light);
    --indicator-box-shadow: ;
    --indicator-color: transparent;
    --indicator-height: 0px;
    --indicator-transform: none;
    --indicator-transition: none;
    --margin-bottom: .1rem;
    --margin-end: .5rem;
    --margin-start: .5rem;
    --margin-top: .1rem;
    --padding-bottom: .0rem;
    --padding-end: .0rem;
    --padding-start: .0rem;
    --padding-top: .0rem;

    // $segment-button-ios-background-color: var(--ion-color-primary);
    // $segment-button-ios-background-color-activated: var(--ion-color-primary);
    // $segment-button-ios-text-color: var(--ion-color-primary-contrast);
    // $segment-button-ios-transition-activated: 100ms all linear;
    // $segment-button-ios-transition-hover: 100ms all linear;
    // $segment-button-ios-transition-active: 100ms all linear;
    // $segment-button-ios-opacity-hover: .1;
    // $segment-button-ios-opacity-active: .16;
    // $segment-button-ios-opacity-activated: 1;
    // $segment-button-ios-opacity-disabled: .3;
    // $segment-button-ios-border-width: 1px;
    // $segment-button-ios-height: 3.2rem;
    // $segment-button-ios-line-height: 3rem;
    // $segment-button-ios-font-size: 1.3rem;
    // $segment-button-ios-border-radius: 1-px;
    // $segment-button-ios-icon-size: 2.6rem;
    // $segment-button-ios-icon-line-height: 2.8rem;
    // $segment-button-ios-toolbar-button-max-width: 100px;
    // $segment-button-ios-toolbar-button-height: 2.6rem;
    // $segment-button-ios-toolbar-line-height: 2.5rem;
    // $segment-button-ios-toolbar-font-size: 1.2rem;
    // $segment-button-ios-toolbar-icon-size: 2.2rem;
    // $segment-button-ios-toolbar-icon-line-height: 2.4rem;
  }

  ion-icon {
    margin: .3em;
    width: 2em;
    height: 2em;
  }

  ion-back-button {
    ion-icon {
      margin: .3em;
      width: 1.5em;
      height: 1.5em;
    }  
  }

  ion-button {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  ion-header {
    // --background: transparent;
    --background: var(--ion-color-light);
    color: var(--ion-color-seondary);
  }
  
  ion-toolbar{
    // --background: transparent;
    --background: var(--ion-color-light);
    color: var(--ion-color-seondary);
  }
  
  ion-label {
    color: var(--ion-color-primary);
    margin: .5em;
  }

  ion-text {
    font-size: 1em;
    font-weight: normal;
    color: var(--ion-color-secondary);
    font-family: "Lato" !important;
    font-style: normal;
    // padding: .5em;
    text-align: start;
  }

  .item {
    background-color: rgb(240, 240, 240);
    border: 1px solid rgb(210, 210, 210);
    border-radius: 9px;
    font-size: 0.9em !important;
  }
  
  .input {
    background-color: rgb(240, 240, 240);
    border: 1px solid rgb(210, 210, 210);
    border-radius: 9px;
    font-size: 0.9em !important;
  }
  
  // @media screen and (min-width: 767px) {
  //   div {
  //     width: 600px;
  //     margin: auto;

  //     .scroll-content {
  //       display: table !important;
  //       width: 100% !important;
  //       height: 100% !important;
  //     }
  //     .scroll {
  //       display: table-cell;
  //       vertical-align: middle;
  //       text-align: center;
  //     }
  //  }
  // }


  .video-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 10px 0 10px;
    .imagediv {
      position: relative;
      height: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      width: 50%;
      display: flex;
      flex: 5;
      .inner {
        position: absolute;
        right: 6px;
        bottom: 6px;
        font-size: 14px;
        background: #222;
        padding: 1px 5px;
        border-radius: 3px;
        color: white;
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 10px;
      flex: 6;
      justify-content: flex-start;
      margin-left: 10px;
      .avatar {
        img {
          border-radius: 50%;
          padding: 10px;
          width: 75px;
        }
      }
      .title-block {
        display: flex;
        flex-direction: column;
        font-family: sans-serif;
        .title {
          font-size: 15px;
          text-align: start;
          color: var(--ion-color-primary);
          }
        .extra {
          display: flex;
          flex-direction: column;
          vertical-align: bottom;
          font-size: 14px;
          // color: gray;
          align-items: flex-start;
          .focus {
            font-size: 14px;
            color: var(--ion-color-primary);
          }
        }
        .details {
          padding: 10px;
          font-family: sans-serif;
          border-bottom: 1px solid #ccc;
          .title {
            font-family: sans-serif;
            font-size: 18px;
            font-weight: bolder;
            text-align: start;
            color: var(--ion-color-primary);
          }
          .views {
            font-size: 14px;
            color: gray;
            line-height: 20px;
            text-align: start;
            color: var(--ion-color-secondary);
          }
          .icons {
            display: flex;
            margin-top: 10px;
            justify-content: space-around;
            .block {
              text-align: center;
              color: gray;
              .icon {
                font-size: 14px;
              }
              .text {
                font-size: 14px;
              }
            }
          }
        }
        .dot {
          height: 3px;
          width: 3px;
          background: gray;
          margin: 0px 6px;
        }
      }
    }
    
  }
  
  
  